
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { DataService } from "@/core/services/DataService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const route = useRouter();

    onMounted(() => {
      const userData = DataService.getAuthUserData()

      if (!userData) {
        route.push('/sign-in')
        return
      }

      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
